import React from "react";
import { useInView } from "react-intersection-observer";
import NavBar from "../../components/navigation-header";
import Footer from "../../components/footer";
import "../../styling/templates.css";
import YouTubeVideo from "./YouTubeVideo";
import OceanSlider from "./ocean-slider";

const OceanWasteManagement = () => {
  // animation
  const [textboxRef, textInView] = useInView({
    triggerOnce: true,
  });

  const videoUrl = "https://youtu.be/MFtnF18g_NY?feature=shared";

  return (
    <div>
      <NavBar />

      {/* session - 1 */}
      <div className="background-scroll-container">
        <div className="scroll-background-image-6"></div>
        <div className="content-sus-6">
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "80vh",
              backgroundColor: "rgba(0, 0, 0, 0.24)",
              zIndex: 0,
            }}
          ></div>
          <div className="video-text-box">
            <div className="video-background-text">
              <h1 className="video-topic">COASTAL PLASTIC</h1>
              <p className="video-topic-para">WASTE MANAGEMENT INITIATIVE</p>
            </div>
          </div>
        </div>
      </div>

      {/* session - 2 */}
      <div style={{ backgroundColor: "#272365" }}>
        <center>
          <div className="ocean-2-position">
            <div className="ocean-2-container">
              <div className="ocean-2-box">
                <p className="ocean-number">73%</p>
                <p className="ocean-text">
                  OF OCEAN LITTER
                  <br />
                  IS PLASTIC
                </p>
              </div>
              <div className="ocean-2-box">
                <p className="ocean-number">12 MILLION TONNES</p>
                <p className="ocean-text">
                  OF PLASTIC IS DUMPED IN
                  <br />
                  THE OCEAN ANNUALLY
                </p>
              </div>
              <div className="ocean-2-box">
                <p className="ocean-number">OVER 100,000</p>
                <p className="ocean-text">
                  MARINE SPECIES HAVE
                  <br />
                  GONE EXTINCT
                </p>
              </div>
            </div>
          </div>
        </center>
      </div>

      {/* session - 3 */}
      <div className="ocean-background">
        <div className="ocean-content-container">
          <center>
            <h2 className="ocean-s2-topic">TOGETHER FOR A CLEANER OCEAN</h2>
            <p className="ocean-s2-para">
              TO PROTECT OUR MARINE LIFE AND SRI LANKA’S BEAUTIFUL
              <br />
              BEACHES, WE WORK CLOSELY WITH THE FISHERMEN COMMUNITY
            </p>
          </center>

          <div className="s2-italic-font-2">
            <p className="s2-font-2">
              *Source:{" "}
              <a href="https://www.nationalgeographic.com/">
                National Geographic
              </a>
              ,{" "}
              <a href="https://science.sciencemag.org/content/347/6223/768">
                ScienceMag
              </a>
              ,{" "}
              <a href="http://www.unesco.org/new/en/natural-sciences/ioc-oceans/focus-areas/rio-20-ocean/blueprint-for-the-future-we-want/marine-pollution/facts-and-figures-on-marine-pollution/">
                Unesco
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* session - 3 */}
      <div className="background-scroll-container">
        <div className="scroll-background-image-7"></div>
        <div className="content-sus">
          <div className="box-3">
            <img
              style={{ height: "300px", width: "300px", marginRight: "20px" }}
              alt=""
              src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/ocean-waste-management/sea-initiative_icon-02-768x770.png"
            />
          </div>
        </div>
      </div>

      <div>
        <center>
          <div className="centered-container">
            <div
              className={`purple-box ${textInView ? "fadeInUp" : ""}`}
              ref={textboxRef}
            >
              <h2 className="purple-text-box">
                Our studies found that local fishermen generate tonnes of annual
                plastic waste when they are at sea. By making it easy and
                worthwhile for fishers to keep things clean out in the deep
                blue, we ensure that the ocean can continue to provide for the
                livelihoods and lifestyle of our hardworking people.
              </h2>
            </div>
          </div>

          <div
            style={{
              marginLeft: "10%",
              marginRight: "10%",
              maxWidth: "100%",
              overflow: "hidden",
              marginTop: "10px",
              marginBottom: "50px",
            }}
          >
            <img
              style={{ width: "100%", height: "auto" }}
              alt=""
              src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/ocean-waste-management/recycle-2.png"
            />
            <div>
              <OceanSlider />
            </div>
          </div>
        </center>
      </div>

      {/* session - 4 */}
      <div className="background-scroll-container">
        <div className="scroll-background-image-8"></div>
        <div className="content-sus-8">
          <center>
            <div className="bottom-box">
              <div className="topic-container">
                <div className="bottom-box-horizontal-line bottom-box-left"></div>
                <div>
                  <p className="bottom-box-topic-text">OUTCOME</p>
                </div>
                <div className="bottom-box-horizontal-line bottom-box-right"></div>
              </div>
              <p className="bottom-box-para">
                WE COLLECT 20 TONNES OF PLASTIC EACH MONTH FROM FISHERS
                <br />
                THAT’S AROUND 1 MILLION BOTTLES!
              </p>
            </div>
          </center>
        </div>
      </div>

      {/* session - 5 */}
      <div style={{ marginTop: "100px", marginBottom: "100px" }}>
        <center>
          <YouTubeVideo videoUrl={videoUrl} />
        </center>
      </div>

      <Footer />
    </div>
  );
};

export default OceanWasteManagement;
