import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const ImageSlider = () => {
  const CustomPrevArrow = ({ onClick }) => (
    <div
      onClick={onClick}
      className="slider-arrow-left"
      style={{
        position: "absolute",
        top: "50%",
        left: "-1%",
        zIndex: 1,
        color: "#808080",
        fontSize: "25px",
        lineHeight: "25px",
        cursor: "pointer",
      }}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div
      onClick={onClick}
      className="slider-arrow-right"
      style={{
        position: "absolute",
        top: "50%",
        right: "-1%",
        zIndex: 1,
        color: "#808080",
        fontSize: "25px",
        lineHeight: "25px",
        cursor: "pointer",
      }}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    pauseOnHover: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 765, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const images = [
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/ocean-waste-management/image-_01.jpg",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/ocean-waste-management/image-_03.jpg",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/ocean-waste-management/image-_04.jpg",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/ocean-waste-management/image-_02.jpg",
  ];

  return (
    <div style={{ width: "97%", margin: "auto" }}>
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div key={idx}>
            <img
              src={img}
              alt={`slide ${idx}`}
              style={{ width: "98%", padding: "10px" }}
            />
          </div>
        ))}
      </Slider>
      <br />
    </div>
  );
};

export default ImageSlider;
